<template>
  <b-form @submit.prevent="submit" class="forgot-passwordstep">
    <div class="mb-2 text-left vgs-font-small">
      {{ labels.PasswordComplexityInfo }}
    </div>
   <label class="vgs-ac-label" for="fm_password">{{ forgotPasswordLabels.PasswordPlaceholder }}</label>
    <div class="position-relative">
      <b-form-input
        id="fm_password"
        :aria-label="forgotPasswordLabels.PasswordPlaceholder"
        :type="this.passwordType"
        maxlength="20"
        v-model="form.password"
        v-on:keyup="set_password_complexity_level_handler"
        @blur="$v.form.password.$touch()"
        :placeholder="forgotPasswordLabels.PasswordPlaceholder + ' *'"
        :class="{ 'is-invalid': $v.form.password.$error }"
        class="mb-3 rounded vgs-form-input"        
      ></b-form-input>
      <div v-if="$v.form.password.$error" class="invalid-feedback error-class" >
        <span v-if="!$v.form.password.required" class="float-left mb-2 mt-n2">{{ forgotPasswordLabels.PasswordRequired }}</span>
    </div>
      <div class="vgs-pw-eye" @click="fm_password_switch()">
        <IconSet icon="eye" state=""></IconSet>
      </div>       
    </div>
    
    

    <div :class="!$v.form.password.$error && $v.form.password.required ? 'mb-5' : ''">
      <PasswordComplexity 
        v-if="!$v.form.password.$error && $v.form.password.required"
        :level="this.password_complexity_level"
      ></PasswordComplexity>
    </div>
     

     <div class="position-relative">
      <label class="vgs-ac-label" for="fm_passwordconfirmation">{{
        forgotPasswordLabels.ConfirmPasswordPlaceholder
      }}</label>
      <b-form-input
        id="fm_passwordconfirmation"
        type="password"
        maxlength="20"
        @blur="$v.form.passwordconfirmation.$touch()"
        v-model="form.passwordconfirmation"
        :placeholder="forgotPasswordLabels.ConfirmPasswordPlaceholder + ' *'"
        class="mb-3 rounded vgs-form-input"
        :class="{ 'is-invalid':  $v.form.passwordconfirmation.$error }"
      ></b-form-input>
      <div v-if="$v.form.passwordconfirmation.$error" class="invalid-feedback error-class" >
        <span v-if="!$v.form.passwordconfirmation.required" class="float-left mb-2 mt-n2">{{ forgotPasswordLabels.ConfirmPasswordRequired }}</span>
        <span v-if="!$v.form.passwordconfirmation.sameAsPassword && $v.form.passwordconfirmation.required" class="float-left mb-2 mt-n2">{{forgotPasswordLabels.ConfirmPasswordNotMatched}}</span>
      </div>
     </div>
    <div class="vgs-error-messages" v-html="get_error_messages"></div>
    <b-button
      type="submit"
      variant="primary"
      class="w-100 p-3 rounded vgs-form-button"
      >{{forgotPasswordLabels.ChangePasswordButtonText}}</b-button
    >
    <div class="vcs-required">* {{ labels.RequiredField }}</div>
  </b-form>
</template>

<script>
import PasswordComplexity from "@/components/PasswordComplexity.vue";
import { required , sameAs  } from "vuelidate/lib/validators";
import { vcm } from "@/mixins/vcm";
import IconSet from '@/structure/IconSet.vue'
import { vvm } from '@/mixins/vvm'
import { customLabels } from "@/mixins/customLabels";
import {mapActions} from "vuex";
export default {
  mixins: [vcm,vvm, customLabels],
  components: {
        PasswordComplexity, IconSet
  },
  name: "ConfirmForgotPasswordForm",
  data() {
    return {
      isSuccess: false,
      labels: {
        Password: this.get_scope_translation("Passwort", "label"),
        PasswordComplexityInfo:this.get_scope_translation("Mind. 8 Zeichen, Groß- & Kleinbuchstaben, Zahlen", "label"),
        PasswordConfirm: this.get_scope_translation("Passwort Wiederholen", "label"),
        RequiredField: this.get_scope_translation("RequiredField", "label"),
      },
      form: {
         password: '',
         passwordconfirmation:'',
      },
      useruuid: null
    };
  },
  mounted() {
    this.useruuid = this.$route.query.useruuid
  },
  validations: {
    form: {
      password: {
            required
      },
      passwordconfirmation:{
        required ,
        sameAsPassword: sameAs('password'),
        },
    }
  },
  methods: {
     ...mapActions({
      setupNewPassword : "setting/setupNewPassword"
    }),
    submit() {
      this.$v.$touch();
      if ( this.$v.form.$invalid || this.password_complexity_level < 4) {
        return;
      }
      this.setupNewPassword({userUUID: this.useruuid, Password: this.form.password}).then((result) => {
        if(result.responseCode === '200' && result.responseMessage === 'Ok') {
          // this.$root.goto('signin');
          this.isSuccess = true;
          this.add_error_message(this.forgotPasswordLabels.SuccessPasswordReset, this.isSuccess);
        } else {
          this.add_error_message(result.responseMessage, this.isSuccess);
          // this.$root.goto('forgotpassword');
        }
      })
    }
  }
};
</script>

<style scoped>
.vgs-form-indent form .form-control.is-invalid {
  background-image: none !important;
}
</style>
