<template>
  <Page mode="jacket">
    <h1>{{ forgotPasswordLabels.ConfirmFPTitle }}</h1>
    <p>{{ forgotPasswordLabels.ConfirmFPDescription }}</p>
    <div class="vgs-form-indent">
      <ConfirmForgotPasswordForm></ConfirmForgotPasswordForm>
    </div>
    <div class="vgs-form-indent text-left">
      <RouteLink
        route="signin"
        icon="arrow-right"
        :text="signIn.GoToSignIn"
      ></RouteLink>
    </div>
  </Page>
</template>

<script>
import Page from "@/structure/Page.vue";
import ConfirmForgotPasswordForm from "@/forms/ConfirmForgotPasswordForm.vue";
import RouteLink from "@/structure/RouteLink.vue";
import { vcm } from "@/mixins/vcm";
import { vvm } from "@/mixins/vvm";
import { customLabels } from "@/mixins/customLabels";

export default {
  mixins: [vcm, vvm, customLabels],
  name: "ConfirmForgotPassword",
  components: {
    Page,
    ConfirmForgotPasswordForm,
    RouteLink,
  },
  data() {
    return {
      labels: {
        GoToSignIn: this.get_translation("App", "GoToSignIn", "label"),
      },
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.875rem;
  line-height: 2.563rem;
  color: $primaryColor;
}
p {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #000;
}
</style>

